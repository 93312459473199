import { Link } from "react-router-dom";

export default function HomeCertificates() {
  return (
    <>
      <div className="certificates-box">
        <p>
          View my certificates on LinkedIn.
          <Link
            to="https://www.linkedin.com/in/teo-ivanus-a5601028a/"
            className="link"
            target="_blank"
          >
            <i class="devicon-linkedin-plain"></i>
          </Link>
        </p>
      </div>
    </>
  );
}
