import React, { useEffect } from "react";

export default function TermsOfUse() {
  useEffect(() => {
    document.title = "Teo Ivanuš | Terms of Use";
  }, []);

  return (
    <section className="section privacy">
      <div className="title-box">
        <h2 className="title">Terms of Use</h2>
        <div className="line"></div>
      </div>
      <div className="privacy-terms">
        <h4>Effective Date: December 11, 2024</h4>
        <h3>Acceptance of Terms</h3>
        <p>
          By accessing or using Teo's Portfolio, you agree to comply with and be
          bound by these Terms of Use. If you do not agree with these terms,
          please do not use this website.
        </p>
        <h3>Use of Website</h3>
        <p>
          You may use this website for lawful purposes only. The following
          activities are strictly prohibited:
        </p>
        <ul>
          <li>Unauthorized access or use of the website's systems.</li>
          <li>Interference with the proper functioning of the website.</li>
          <li>
            Attempting to probe, scan, or test the website's security
            vulnerabilities.
          </li>
        </ul>
        <h3>Intellectual Property</h3>
        <p>
          All materials on this website, including text, images, logos, and
          code, are the property of Teo Ivanuš and are protected under copyright
          laws. Unauthorized reproduction or use of these materials is
          prohibited.
        </p>
        <h3>User Submissions</h3>
        <p>
          By submitting content to this website, you grant Teo Ivanuš a
          royalty-free license to use, modify, and display the content for any
          purpose.
        </p>
        <h3>Disclaimer of Warranties</h3>
        <p>
          This website is provided "as is" without any warranties, express or
          implied. While every effort is made to ensure accuracy, Teo Ivanuš is
          not responsible for errors or omissions.
        </p>
        <h3>Limitation of Liability</h3>
        <p>
          Teo Ivanuš is not liable for any damages arising from the use or
          inability to use this website.
        </p>
        <h3>Changes to Terms</h3>
        <p>
          These Terms of Use may be updated at any time. Continued use of the
          website constitutes acceptance of the revised terms.
        </p>
        <h3>Governing Law</h3>
        <p>
          These terms are governed by the laws of Croatia. Any disputes will be
          resolved in Croatian courts.
        </p>
        <h3>Contact Information</h3>
        <p>
          For questions or concerns, please email:{" "}
          <a href="mailto:help.teoivanus@gmail.com">help.teoivanus@gmail.com</a>
        </p>
      </div>
    </section>
  );
}
