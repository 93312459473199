import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NKPodsused from "../../../Assets/Projects/NK-Podsused.jpg";
import Autoconnect from "../../../Assets/Projects/Autoconnect.jpg";
import Wazapp from "../../../Assets/Projects/Wazapp.jpg";

export default function HomeProjects() {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const projects = [
    {
      id: 1,
      name: "nk-podsused",
      image: NKPodsused,
      alt: "NK Podsused",
      visitLink: "https://nkpodsused.com",
      codeLink: "https://github.com/TeoIvanus/NK-Podsused",
    },
    {
      id: 2,
      name: "autoconnect",
      image: Autoconnect,
      alt: "Autoconnect",
      visitLink: "https://autoconnect.hr/",
      codeLink: "https://github.com/TeoIvanus/Autoconnect",
    },
    {
      id: 3,
      name: "wazaap",
      image: Wazapp,
      alt: "Wazaap",
      visitLink: "https://chatapp-teoivanus.pages.dev/",
      codeLink: "https://github.com/TeoIvanus/ChatApp-TeoIvanus",
    },
  ];

  return (
    <>
      <div className="grid-2">
        {projects.map((project) => (
          <ProjectBox
            key={project.id}
            image={project.image}
            alt={project.alt}
            visitLink={project.visitLink}
            codeLink={project.codeLink}
          />
        ))}
      </div>
      <Link
        className="more-btn"
        to="/projects"
        onClick={() => handleClick("/projects")}
      >
        All Projects &rarr;
      </Link>
    </>
  );
}

const ProjectBox = ({ image, alt, visitLink, codeLink }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="project-box"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={image} alt={alt} className="project-image" />
      {isHovered && (
        <div className="projects-btns">
          <button
            className="button"
            onClick={() => window.open(visitLink, "_blank")}
          >
            Visit Page
          </button>
          <button
            className="button-link"
            onClick={() => window.open(codeLink, "_blank")}
          >
            Code
          </button>
        </div>
      )}
    </div>
  );
};
