import React from "react";
import { Link } from "react-router-dom";
// Images
import TLogo from "../../../Assets/Teo/T.png";

export default function NotFoundPage() {
  const handleClick = (path) => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="section error-page">
        <img src={TLogo} alt="T Logo" />
        <h2>PAGE NOT FOUND!</h2>
        <p>Please check the URL you have entered.</p>
        <Link className="more-btn" to="/" onClick={() => handleClick("/")}>
          Home
        </Link>
      </div>
    </>
  );
}
