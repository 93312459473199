export default function Skills() {
  return (
    <>
      <div className="skills">
        <div className="skill-box">
          <i className="devicon-html5-plain"></i>
          <h5>HTML5</h5>
        </div>
        <div className="skill-box">
          <i className="devicon-css3-plain"></i>
          <h5>CSS3</h5>
        </div>
        <div className="skill-box">
          <i className="devicon-sass-original"></i>
          <h5>Sass</h5>
        </div>
        <div className="skill-box">
          <i className="devicon-bootstrap-plain"></i>
          <h5>Bootstrap</h5>
        </div>
        <div className="skill-box">
          <i className="devicon-javascript-plain"></i>
          <h5>JavaScript</h5>
        </div>
        <div className="skill-box">
          <i className="devicon-react-original"></i>
          <h5>React</h5>
        </div>
        <div className="skill-box">
          <i className="devicon-firebase-plain"></i>
          <h5>Firebase</h5>
        </div>
        <div className="skill-box">
          <i className="devicon-cloudflare-plain"></i>
          <h5>Cloudflare</h5>
        </div>
        <div className="skill-box">
          <i className="devicon-github-original"></i>
          <h5>GitHub</h5>
        </div>
        <div className="skill-box">
          <i className="devicon-vscode-plain"></i>
          <h5>VS Code</h5>
        </div>
        <div className="skill-box">
          <i className="devicon-photoshop-plain"></i>
          <h5>Photoshop</h5>
        </div>
        <div className="skill-box">
          <i className="devicon-canva-original"></i>
          <h5>Canva</h5>
        </div>
      </div>
    </>
  );
}
