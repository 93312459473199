import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Navigation() {
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  // Close Nav-list when new page is open
  const closeMenu = () => {
    setShow(false);
  };

  // Scroll to top of the page
  const handleClick = (path) => {
    closeMenu();
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <nav id="navigation">
      <ul className={show ? "nav-links active" : "nav-links"}>
        <li>
          <Link
            className="navbar-links"
            to="/"
            onClick={() => handleClick("/")}
          >
            HOME
          </Link>
        </li>
        <li>
          <Link
            className="navbar-links"
            to="/projects"
            onClick={() => handleClick("/")}
          >
            PROJECTS
          </Link>
        </li>
        <li>
          <button
            className="mail-btn"
            onClick={() =>
              (window.location.href = "mailto:help.teoivanus@gmail.com")
            }
          >
            Mail Me
          </button>
        </li>

        <div className="social-links">
          <Link to="https://github.com/TeoIvanus" target="_blank">
            <i className="devicon-github-original"></i>
          </Link>
          <Link
            to="https://www.linkedin.com/in/teo-ivanus-a5601028a/"
            target="_blank"
          >
            <i class="devicon-linkedin-plain"></i>
          </Link>
        </div>
      </ul>

      <div
        className={show ? "hamburger active" : "hamburger"}
        onClick={() => setShow(!show)}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
}
