/* import { Link } from "react-router-dom"; */
import Teo from "../../../Assets/Teo/Teo.png";

export default function HomeMain() {
  return (
    <>
      <main>
        <div className="left-side">
          <div className="name-box">
            <h5>Hello, my name is</h5>
            <div className="line"></div>
            <h1>Teo Ivanuš</h1>
            <h3>Front End Developer</h3>
          </div>
        </div>
        <div className="right-side">
          <div className="profile-img-box">
            <img className="profile-photo" src={Teo} alt="Teo Ivanuš" />
          </div>
        </div>
      </main>
    </>
  );
}
