import { Link, useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  // Scroll to top of the page
  const handleClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };
  let year = new Date().getFullYear();
  return (
    <>
      <footer>
        <div className="footer-links">
          <Link
            className="link"
            to="/privacy-policy"
            onClick={() => handleClick("/")}
          >
            Privacy Policy
          </Link>
          <Link
            className="link"
            to="/terms-of-use"
            onClick={() => handleClick("/")}
          >
            Terms of Use
          </Link>
        </div>
        <p>
          &copy; <span id="year">{year}</span> Teo Ivanuš.
        </p>
        <Link
          to="https://www.linkedin.com/in/teo-ivanus-a5601028a/"
          className="social-link"
          target="_blank"
        >
          <i class="devicon-linkedin-plain"></i>LinkedIn
        </Link>
        <Link
          to="https://github.com/TeoIvanus"
          target="_blank"
          className="social-link"
        >
          <i className="devicon-github-original"></i>GitHub
        </Link>
      </footer>
    </>
  );
}
