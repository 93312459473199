import { useEffect } from "react";

export default function PrivacyPolicy() {
  useEffect(() => {
    document.title = "Teo Ivanuš | Privacy Policy";
  }, []);

  return (
    <section className="section privacy">
      <div className="title-box">
        <h2 className="title">Privacy Policy</h2>
        <div className="line"></div>
      </div>
      <div className="privacy-terms">
        <h4>Effective Date: December 11, 2024</h4>

        <h3>Overview</h3>
        <p>
          Thank you for visiting Teo's Portfolio. This Privacy Policy explains
          how we collect, use, disclose, and safeguard your personal information
          when you visit this website.
        </p>

        <h3>Information We Collect</h3>
        <p>
          We may collect personal information you voluntarily provide, such as
          your name, email address, and any other details you share when
          interacting with the website.
        </p>

        <h3>Use of Your Information</h3>
        <p>Collected information may be used to:</p>
        <ul>
          <li>Personalize your experience on the website.</li>
          <li>Respond to inquiries and provide support.</li>
          <li>Improve website functionality based on feedback.</li>
          <li>Send updates or relevant information via email.</li>
        </ul>

        <h3>Protection of Your Information</h3>
        <p>
          We implement security measures to protect your personal information,
          ensuring your data is safe when you access this website.
        </p>

        <h3>Cookies</h3>
        <p>
          Cookies may be used to enhance your experience. You can configure your
          browser to warn about or disable cookies. Note that some site
          functionalities might be affected if cookies are disabled.
        </p>

        <h3>Third-Party Disclosure</h3>
        <p>
          We do not sell, trade, or transfer your personal information to third
          parties unless explicitly notified in advance.
        </p>

        <h3>Third-Party Links</h3>
        <p>
          This website may include links to third-party sites, which have
          independent privacy policies. We are not responsible for the content
          or practices of these external sites.
        </p>

        <h3>Your Consent</h3>
        <p>
          By using this website, you consent to the practices outlined in this
          Privacy Policy.
        </p>

        <h3>Privacy Policy Updates</h3>
        <p>
          We may revise this Privacy Policy periodically. Updates will be posted
          on this page, and your continued use of the website constitutes
          acceptance of these changes.
        </p>

        <h3>Contact Information</h3>
        <p>
          For any questions about this Privacy Policy, please contact us at:{" "}
          <a href="mailto:help.teoivanus@gmail.com">help.teoivanus@gmail.com</a>
          .
        </p>
      </div>
    </section>
  );
}
