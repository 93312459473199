export default function HomeAbout() {
  return (
    <>
      <div className="about-me">
        <p>
          I'm a 24-year-old, based in Zagreb, with a strong passion for creating
          visually appealing and intuitive websites.
        </p>
        <p>
          I completed a 6-month Front-end Developer course at Algebra, and after
          that, I continued my studies in Information Technology at Baltazar
          College in Zaprešić.
        </p>
      </div>
    </>
  );
}

/*
 */
