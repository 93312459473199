import React from "react";
import ReactDOM from "react-dom/client";
import "./CSS/App.scss";
import {
  Home,
  Footer,
  Navigation,
  NotFoundPage,
  Projects,
  TermsOfUse,
  PrivacyPolicy,
} from "./Components";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Navigation />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      {/* ERRORS */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
    <Footer />
  </BrowserRouter>
);
