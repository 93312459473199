import React from "react";
import HomeMain from "./HomeMain";
import HomeAbout from "./HomeAbout";
import Skills from "./HomeSkills";
import HomeProjects from "./HomeProjects";
import HomeCertificates from "./HomeCertificates";

export default function Home() {
  return (
    <>
      <div className="section">
        <HomeMain />
        <h2>About me</h2>
        <div className="title-line"></div>
        <HomeAbout />
        <h2>Certificates</h2>
        <div className="title-line"></div>
        <HomeCertificates />
        <h2>Skills</h2>
        <div className="title-line"></div>
        <Skills />
        <h2>My projects</h2>
        <div className="title-line"></div>
        <HomeProjects />
      </div>
    </>
  );
}
